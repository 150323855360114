import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";

import "./Footer.css";
import Logo from "../../../assets/Logo/satesfylogo3.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  // faFacebookF,
  // faFacebookF,
  // faInstagram,
  faLinkedinIn,
  // faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faArrowRight, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../../context/AppContext";
import { servicesArr, industriesArr } from "../../../utils/Data";
// import chatboot from "../../../assets/Images/chatboticon.png";
// import Chatboot from "../../components/chatboot/Chabot.jsx";
// import { RxCross2 } from "react-icons/rx";

export default function Footer() {
  const { dark } = useStateContext();
  // const [isOpen, setIsOpen] = useState(() => {
  //   const storedValue = localStorage.getItem('chatIsOpen');
  //   return storedValue !== null ? JSON.parse(storedValue) : false;
  // });
  // const toggleChat = () => {
  //   setIsOpen(prevIsOpen => !prevIsOpen);
  // };
  // useEffect(() => {
  //   localStorage.setItem('chatIsOpen', JSON.stringify(isOpen));
  // }, [isOpen]);

  const navigate = useNavigate();

  const path = window.location.pathname;
  let currentYear = new Date().getFullYear();

  const handleClick = async (e) => {
    if (path !== "/") {
      navigate("/", { state: { dataObj: e } });
    }

    if (e === "services") {
      scroller.scrollTo("/services", {
        duration: 200,
        smooth: true,
        offset: 180,
      });
    }

    if (e === "slider") {
      scroller.scrollTo("/slider", {
        duration: 200,
        smooth: true,
        offset: -10,
      });
    }

    if (e === "home") {
      scroller.scrollTo("/home", {
        duration: 200,
        smooth: true,
        offset: 0,
      });
    }
    if (e === "Our clients") {
      await navigate("/");
      scroller.scrollTo("/Our clients", {
        duration: 200,
        smooth: true,
        offset: -60,
      });
    }
  };

  const handleEmail = () => {
    const emailAddress = "contact@sparkixtech.com";
    const mailtoLink = `mailto:${emailAddress}`;
    window.location.href = mailtoLink;
  };

  return (
    <div>
      {/* <button type="button" onClick={()=>onClickChat()}>onClick CHAT</button> */}
      {/* <Chatboot isOpen={isOpen} setIsOpen={setIsOpen} /> */}

      <div className="footer-main" style={{ backgroundColor: dark }}>
        {/* <div onClick={toggleChat}>
          {!isOpen?<img className="spark-chatbot-image" src={chatboot}  alt=""/>:
          <div className="spark-chatbot-image2">
            <div className={`sprak-cros-container ${isOpen ? 'rotate' : ''}`}>
              <RxCross2 size={20} style={{ color: "white",fontSize:'20px' }} />
            </div>
          </div>}
        </div> */}
        <div className="container-fluid">
          <div className="row footer-boder">
            <div className="col-lg-5 col-md-6">
              <div className="footer-wrap">
                <div
                  className="footer-brand"
                  onClick={() => handleClick("home")}
                >
                  <img src={Logo} alt="Loading" />
                </div>
                <p>
                  Sparkix technologies is an IT services and consulting company
                  that builds, designs, and offers top IT services to businesses
                  of all sizes covering diverse industries.
                </p>

                <p className="mb-1">
                  <strong>Pakistan Office:</strong> DC Colony, Gujranwala, 52250
                  Punjab Pakistan
                </p>
                <p className="mt-1">
                  <strong>USA Office:</strong> 42 Broadway, 12th Floor, New
                  York, NY 10004
                </p>

                <div className="footer-contact">
                  <div>
                    contact@sparkixtech.com{" "}
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      style={{ cursor: "pointer" }}
                      onClick={handleEmail}
                    />
                  </div>

                  <button
                    style={{ border: "1px solid white" }}
                    onClick={() => navigate("/schedule-call")}
                  >
                    Schedule a Call <FontAwesomeIcon icon={faArrowRight} />
                  </button>
                </div>

                <div className="footer-icons-wrap">
                  <a
                    href="https://www.facebook.com/profile.php?id=61556655330083"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faFacebookF}
                      className="f-icon f-divider"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/sparkixtechnologies"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      className="f-icon f-divider"
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/satesfy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faLinkedinIn} className="f-icon" />
                  </a>
                  {/* <FontAwesomeIcon icon={faXTwitter} className="f-icon" /> */}
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div className="footer-wrap2">
                <h1>Company</h1>
                <p onClick={() => navigate("/")}>Home</p>
                <p onClick={() => navigate("/about-us")}>About us</p>
                <p onClick={() => navigate("/schedule-call")}>Contact us</p>
                <p onClick={() => handleClick("Our clients")}>Our Clients</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div className="footer-wrap2">
                <h1>Industries</h1>
                {industriesArr.map((t, i) => (
                  <p key={i} onClick={() => handleClick("slider")}>
                    {t}
                  </p>
                ))}
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="footer-wrap2">
                <h1>Services</h1>
                {servicesArr.map((t, i) => (
                  <p key={i} onClick={() => handleClick("services")}>
                    {t}
                  </p>
                ))}
              </div>
            </div>
          </div>

          <div className="footer-copywrite">
            © {currentYear} <Link>Sparkixtech.</Link> All rights reserved
          </div>
        </div>
      </div>
    </div>
  );
}
